import axios from "./requests";


// 登录
export const Login = (data) => {
  return axios({
    url: "/server/login",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token'),
      },
    },
  });
};

// 退出登录
export const quit = (data) => {
  return axios({
    url: "/server/signOut",
    method: "put",
    data,
    config: {
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 修改密码
export const Revamp = (data) => {
  return axios({
    url: "/server/changePassword",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 注销账号
export const Write = (data)=>{
  return axios({
    url:'/ztentrance/logout',
    method:'post',
    data,
    config: {
      headers: {
        timeout: 10000,
      },
    },
  })
}
// 工会管理 工会列表
export const Labor =(data)=>{
  return axios({
    url:'/ztunion/list',
    method:'post',
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
      },
    },
  })
}

// 增加工会

export const Increase = (data)=>{
  return axios({
    url:'/ztunion/add',
    method:'post',
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
      },
    },
  })
}

// 删除工会  

export const Cancel=(data)=>{
  return axios({
    url:'/ztunion/delete',
    method:'delete',
    data,
    config: {
      headers: {
        timeout: 10000,
      },
    },
  })
}

// 修改工会名称

export const Modify = (data)=>{
  return axios({
    url:'/ztunion/update',
    method:'put',
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
      },
    },
  })
}
// 查询工会信息
export const Message = (data)=>{
  return axios({
    url:'/ztunion/unioninfo',
    method:'post',
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
      },
    },
  })
}
// 分工会查询
export const Branch = (data)=>{
  return axios({
    url:'/ztunion/branch/list',
    method:'post',
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
      },
    },
  })
}

// 分支工会添加
export const Affiliated = (data)=>{
  return axios({
    url:'/ztunion/branch/add',
    method:'post',
    data,
    config: {
      headers: {
        // 'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
      },
    },
  })
}

// 分支工会删除
export const Expurgate = (data)=>{
  return axios({
    url:'/ztunion/branch/delete',
    method:'delete',
    data,
    config: {
      headers: {
        // 'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
      },
    },
  })
}

// 分支工会编辑
export const Edit = (data)=>{
  return axios({
    url:'/ztunion/branch/update',
    method:'put',
    data,
    config: {
      headers: {
        // 'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
      },
    },
  })
}

// 出游管理方案列表
export const scheme = (data) => {
  return axios({
    url: "/tour/schemeList",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理方案列表添加
export const Addscheme = (data) => {
  return axios({
    url: "/tour/addScheme",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理方案列表编辑
export const Editscheme = (data) => {
  return axios({
    url: "/tour/schemeInfo",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理方案列表编辑保存
export const Savetscheme = (data) => {
  return axios({
    url: "/tour/updateScheme",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        // 'Content-Type':'multipart/form-data',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理分类列表
export const Classify = (data) => {
  return axios({
    url: "/tour/classifyList",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理分类列表添加
export const AddClassify = (data) => {
  return axios({
    url: "/tour/addClassify",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        // 'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理分类列表编辑
export const EditClassify = (data) => {
  return axios({
    url: "/tour/classifyInfo",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理分类列表保存编辑
export const SeaveClassify = (data) => {
  return axios({
    url: "/tour/updateClassify",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        // 'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理活动列表
export const Activity = (data) => {
  return axios({
    url: "/tour/activityList",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理活动列表添加
export const AddActivity = (data) => {
  return axios({
    url: "/tour/addActivity",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        // 'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理活动列表编辑信息
export const EditActivity = (data) => {
  return axios({
    url: "/tour/activityInfo",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 出游管理活动列表保存编辑信息
export const SaveActivity = (data) => {
  return axios({
    url: "/tour/updateActivity",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        // 'Content-Type':'multipart/form-data',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 获取积分发行统计
export const JFissue = (data) => {
  return axios({
    url: "/integral/issueStatistical",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        // 'Content-Type':'multipart/form-data',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 分页获取积分发行列表
export const Listissue = (data) => {
  return axios({
    url: "/integral/issueList",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'Content-Type':"application/x-www-form-urlencoded",
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 添加-发行积分
export const AddFXintegral= (data) => {
  return axios({
    url: "/integral/issueIntegral",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        // 'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 提货券发统计
export const THstatistics= (data) => {
  return axios({
    url: "/api/v1/cash/issueStatistical",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        // 'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 提货券列表
export const THlist= (data) => {
  return axios({
    url: "/api/v1/cash/issueList",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 添加-提货券发行
export const THpublish= (data) => {
  return axios({
    url: "/api/v1/cash/issueCash",
    method: "post",
    data,
    config: {
      headers: {
        timeout: 10000,
        // 'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};


// 数据列表
export const FYintegral= (data) => {
  return axios({
    url: "integral/allocationList",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 积分分配工会
export const Zzlist= (data) => {
  return axios({
    url: "/union/unionList",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 积分所属工会
export const Zzzlist= (data) => {
  return axios({
    url: "/union/unitList",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};


// 点击提交
export const ZNentry= (data) => {
  return axios({
    url: "/integral/allocationIntegral",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/json',
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};


// 积分统计(已派发积分、在途积分、已换购积分) 以及 工会可派发积分
export const PFintegral= (data) => {
  return axios({
    url: "/integral/statisticalIntegral",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 中台组织列表查询
export const ZClist= (data) => {
  return axios({
    url: "/union/unitList",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};

// 积分分配导出接口
export const FPtotal= (data) => {
  return axios({
    url: "/integral/exportAllocationIntegralExcel",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 获取系统所有下载信息
export const FPall= (data) => {
  return axios({
    url: "/union/unionDownload",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 积分返还列表
export const PointsList= (data) => {
  return axios({
    url: "/integral/queryRefundIntegralPage",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 返还积分添加
export const ListPoints= (data) => {
  return axios({
    url: "/integral/saveRefundIntegral",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/json',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 短信推送列表
export const smsPush= (data) => {
  return axios({
    url: "/api/v1/message/queryMessagePage",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 短信模板列表
export const smsTemplate= (data) => {
  return axios({
    url: "/api/v1/message/queryMessageTemplateList",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 短信模板添加
export const templateAdd= (data) => {
  return axios({
    url: "/api/v1/message/saveMessageTemplate",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/json',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 短信模板详情
export const templateInfo= (data) => {
  return axios({
    url: "/api/v1/message/queryMessageTemplateInfo",
    method: "post",
    data,
    config: {
      headers: {
         'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 短信模板编辑
export const templateEdit= (data) => {
  return axios({
    url: "/api/v1/message/editMessageTemplate",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/json',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 开发功能列表
export const functionList= (data) => {
  return axios({
    url: "/api/v1/development/queryDevelopmentPage",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 开发功能添加
export const functionAdd= (data) => {
  return axios({
    url: "/api/v1/development/saveDevelopment",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/json',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 开发功能详情
export const functionInfo= (data) => {
  return axios({
    url: "/api/v1/development/queryDevelopmentInfo",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 开发功能编辑
export const functionEdit= (data) => {
  return axios({
    url: "/api/v1/development/editDevelopment",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/json',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 更新日志列表
export const logsList= (data) => {
  return axios({
    url: "/api/v1/update/queryUpdatePage",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 开发日志添加
export const logsAdd= (data) => {
  return axios({
    url: "/api/v1/update/saveUpdate",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/json',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 开发日志详情
export const logsInfo= (data) => {
  return axios({
    url: "/api/v1/update/queryUpdateInfo",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/x-www-form-urlencoded',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};
// 开发日志编辑
export const logsEdit= (data) => {
  return axios({
    url: "/api/v1/update/editUpdate",
    method: "post",
    data,
    config: {
      headers: {
        'Content-Type':'application/json',
        'accessToken':sessionStorage.getItem('token')
      },
    },
  });
};